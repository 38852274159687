import React from 'react';
import ComingSoon from 'Pages/ComingSoon';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import LandingPage from 'Pages/LandingPage';

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/coming-soon">
          <ComingSoon />
        </Route>

        <Route path="/" exact>
          <LandingPage />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
