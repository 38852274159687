import React, { useState } from 'react';
// import PropTypes from 'prop-types';
import noScroll from 'no-scroll';
import styles from './index.module.scss';
import siteLogo from 'Assets/svg/siteLogo.svg';
import mobileMenuImg from 'Assets/images/mobile-bip.png';

const NavBar = props => {
  const [navOpen, setNavOpen] = useState(false);

  const keyPressHandler = e => {
    if (e.keycode === 13) {
      setNavOpen(!navOpen);
    }
  };

  const toggleNav = () => {
    setNavOpen(!navOpen);
    noScroll.toggle();
  };

  return (
    <header>
      <div className={styles.siteLogo}>
        <img src={siteLogo} alt="site logo" />
      </div>

      <nav className={navOpen ? styles.open : null}>
        <div
          className={styles.toggleMenu}
          onClick={toggleNav}
          onKeyPress={keyPressHandler}
          role="menubar"
          tabIndex={0}
        >
          <div></div>
          <div></div>
          <div></div>
        </div>

        <ul className={styles.navBar}>
          <li className={true ? styles.active : null}>
            <a href="/">Home</a>
          </li>
          <li>
            <a href="/">About Us</a>
          </li>
          <li>
            <a href="/">Edutech</a>
          </li>
          <li>
            <a href="/">Contact Us</a>
          </li>
          <li>
            <a href="/">Sign Up</a>
          </li>

          <div className={styles.navBar__image}>
            <img src={mobileMenuImg} alt="bottom nav icon" />
          </div>
        </ul>
      </nav>
    </header>
  );
};

NavBar.propTypes = {};

export default NavBar;
