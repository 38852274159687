import { SAMPLE_CONSTANT } from 'Store/constants';

const initialState = {
  sample: true
};

const sampleReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAMPLE_CONSTANT:
      return state;

    default:
      return state;
  }
};

export default sampleReducer;
