import React from 'react';
// import PropTypes from 'prop-types';
import styles from './index.module.scss';
import siteLogoWhite from 'Assets/svg/bipedge-logo-white.svg';

import footerImg from 'Assets/images/bipedge-footer-image.png';
import footerImg_Web from 'Assets/images/bipedge-footer-image.webp';

const Footer = props => {
  return (
    <footer>
      <div className={styles.footer__image}>
        <picture>
          <source srcSet={footerImg_Web} type="image/webp" />
          <source srcSet={footerImg} type="image/png" />
          <img src={footerImg} alt="bi logo split in half with green overlay" />
        </picture>
      </div>
      <div className={styles.footer__container}>
        <div className={styles.footer__top}>
          <div className={styles.logoFormContainer}>
            <div className={styles.siteLogo}>
              <img src={siteLogoWhite} alt="" />
            </div>

            <form onSubmit={e => e.preventDefault()}>
              <p>Subscribe to our newsletter</p>
              <label htmlFor="email">
                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Email Address"
                />
              </label>
              <button>Subscribe</button>
            </form>
          </div>

          <nav className={styles.footer__top__nav}>
            <ul>
              <li>
                <a href="/">About Us</a>
              </li>
              <li>
                <a href="/">Edutech</a>
              </li>
              <li>
                <a href="/">Contact Us</a>
              </li>
              <li>
                <a href="/">Login/Sign Up</a>
              </li>
            </ul>
          </nav>

          <div className={styles.right__footer__address}>
            <div className={styles.footer__address}>
              <h5>Address</h5>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod
              </p>
            </div>

            <div className={styles.footer__address}>
              <h5>Address</h5>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod
              </p>
            </div>
          </div>
        </div>

        <div className={styles.horizontalBar} />

        <div className={styles.footer__bottom}>
          <nav className={styles.bottom__nav}>
            <ul>
              <li>
                <a href="/">Terms and conditions</a>
              </li>
              <li>
                <a href="/">Privacy Policy</a>
              </li>
            </ul>
          </nav>
          <p className={styles.footer__copyright}>
            Copyright &copy; Bipedge 2019
          </p>
        </div>
      </div>
    </footer>
  );
};

Footer.propTypes = {};

export default Footer;
