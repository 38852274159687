import React from 'react';
import { useHistory } from 'react-router-dom';
// import PropTypes from 'prop-types';
import styles from './index.module.scss';
import NavBar from 'Components/NavBar';
import Footer from 'Components/Footer';

import headerSideWebImg from 'Assets/images/bipedge-header.webp';
import headerSideImg from 'Assets/images/bipedge-header copy.png';
import ipSideImg from 'Assets/images/bipedge-second-purple-overlay.png';
import ipSideWebpImg from 'Assets/images/bipedge-second-purple-overlay.webp';
import gridImgWebOne from 'Assets/images/LandingPage/homepage-overlay-1.webp';
import gridImgOne from 'Assets/images/LandingPage/homepage-overlay-1.jpg';
import gridImgTwo from 'Assets/images/LandingPage/homepage-overlay-2.jpg';
import gridImgWebTwo from 'Assets/images/LandingPage/homepage-overlay-2.webp';
import gridImgThree from 'Assets/images/LandingPage/homepage-overlay-3.jpg';
import gridImgWebThree from 'Assets/images/LandingPage/homepage-overlay-3.webp';
import eduTechImg1_web from 'Assets/images/LandingPage/eduTechImg1.webp';
import eduTechImg1_web_2x from 'Assets/images/LandingPage/eduTechImg1@2x.webp';
import eduTechImg1 from 'Assets/images/LandingPage/eduTechImg1.jpg';
import eduTechImg1_2x from 'Assets/images/LandingPage/eduTechImg1@2x.jpg';
import eduTechImg2_web from 'Assets/images/LandingPage/eduTechImg2.webp';
import eduTechImg2_web_2x from 'Assets/images/LandingPage/eduTechImg2@2x.webp';
import eduTechImg2 from 'Assets/images/LandingPage/eduTechImg2.jpg';
import eduTechImg2_2x from 'Assets/images/LandingPage/eduTechImg2@2x.jpg';
import testimonialImg from 'Assets/images/LandingPage/testimonial-image.jpg';
import chevronArrow from 'Assets/svg/down-arrow.svg';
import leftQuote from 'Assets/svg/left-quote.svg';
import purpleCircle from 'Assets/svg/purpleCircle.svg';
import greenCircle from 'Assets/svg/greenCircle.svg';
import pexelsImg1 from 'Assets/images/LandingPage/pexels-photo-1.png';
import pexelsImg2 from 'Assets/images/LandingPage/pexels-photo-2.png';
import pexelsImg3 from 'Assets/images/LandingPage/pexels-photo-3.png';
import pexelsImg4 from 'Assets/images/LandingPage/pexels-photo-4.png';
import cardPlanIcon from 'Assets/svg/plans-icon.svg';

const LandingPage = props => {
  const history = useHistory();
  const handleClick = () => {
    history.push('/coming-soon');
  };

  return (
    <div className={styles.home}>
      <NavBar />

      <div className={styles.grayBackground}>
        <section className={styles.heroSection}>
          <section className={styles.heroText}>
            <p className={styles.sectionText}>
              <span>Empowering employees</span> <br />
              <span>to SUCCESSFULLY</span> <br />
              Transit from Paid <br />
              to <span>Self employment</span>
            </p>

            <button
              type="button"
              className={styles.learnMoreBtn}
              onClick={handleClick}
            >
              Learn more
            </button>
          </section>

          <section className={styles.rightSection}>
            <picture>
              <source srcSet={headerSideWebImg} type="image/webp" />
              <source srcSet={headerSideImg} type="image/png" />
              <img
                src={headerSideImg}
                alt="bi logo split in half with green overlay"
              />
            </picture>
          </section>
        </section>
      </div>

      <section className={styles.aboutSection}>
        <div className={styles.aboutSection__container}>
          <div className={styles.aboutSection__container__text}>
            <div className={styles.aboutHeading}>
              <span></span>
              <h2>ABOUT US</h2>
            </div>

            <p className={styles.aboutParagraph}>
              <span>Bipedge is a company dedicated to</span> <br />
              <span>
                empowering employees to <br />{' '}
              </span>
              <span>
                transition from paid <br /> to self-employment
              </span>
            </p>

            <p className={styles.aboutSubParagraph}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.
            </p>

            <button onClick={handleClick}>Learn more</button>
          </div>
        </div>
        <section className={styles.aboutSection__rightImg}>
          <picture>
            <source srcSet={ipSideWebpImg} type="image/webp" />
            <source srcSet={ipSideImg} type="image/png" />
            <img
              src={ipSideImg}
              alt="bi logo split in half with green overlay"
            />
          </picture>
        </section>
      </section>

      <section className={styles.whatWeDoSection}>
        <div className={styles.whatWeDoSection__container}>
          <div className={styles.whatWeDoSection__heading}>
            <span></span>
            <h2>WHAT WE DO</h2>
          </div>

          <p className={styles.whatWeDoSection__paragraph}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.
          </p>
        </div>
      </section>

      <section className={styles.imageGrid}>
        <div>
          <picture>
            <source srcSet={gridImgWebOne} type="image/webp" />
            <source srcSet={gridImgOne} type="image/jpeg" />
            <img src={gridImgOne} alt="box one" />
          </picture>
        </div>
        <div>
          <picture>
            <source srcSet={gridImgWebTwo} type="image/webp" />
            <source srcSet={gridImgTwo} type="image/jpeg" />
            <img src={gridImgTwo} alt="box two" />
          </picture>
        </div>
        <div>
          <picture>
            <source srcSet={gridImgWebThree} type="image/webp" />
            <source srcSet={gridImgThree} type="image/jpeg" />
            <img src={gridImgThree} alt="box three" />
          </picture>
        </div>
      </section>

      <section className={styles.eduTechSection}>
        <div className={styles.eduTechSection__container}>
          <div className={styles.eduTechSection__heading}>
            <span></span>
            <h2>EDUTECH</h2>
          </div>
          <p className={styles.eduTechParagraph}>
            <span>Bipedge is a company dedicated to</span> <br />
            <span>
              empowering <br /> employees to <br />{' '}
            </span>
            <span>
              transition from paid <br /> to self-employment
            </span>
          </p>

          <button onClick={handleClick}>Learn more</button>
        </div>

        <div className={styles.eduTechGrid}>
          <div>
            <picture>
              <source
                srcSet={`${eduTechImg1_web}, ${eduTechImg1_web_2x} 2x`}
                type="image/webp"
              />
              <source
                srcSet={`${eduTechImg1}, ${eduTechImg1_2x} 2x`}
                type="image/jpeg"
              />
              <img
                srcSet={`${eduTechImg1}, ${eduTechImg1_2x} 2x`}
                alt="box three"
              />
            </picture>
          </div>
          <div className={styles.eduTechGrid__two}>
            <picture>
              <source
                srcSet={`${eduTechImg2_web}, ${eduTechImg2_web_2x} 2x`}
                type="image/webp"
              />
              <source
                srcSet={`${eduTechImg2}, ${eduTechImg2_2x} 2x`}
                type="image/jpeg"
              />
              <img
                srcSet={`${eduTechImg2}, ${eduTechImg2_2x} 2x`}
                alt="box three"
              />
            </picture>
          </div>
        </div>
      </section>

      <section className={styles.subscription__section}>
        <div className={styles.subscription__paragraph}>
          <span></span>
          <h2>SUBSCRIPTION</h2>
        </div>

        <div className={styles.subscription__section__plans}>
          <div className={styles.subscription__card_one}>
            <h3>REGULAR PLAN</h3>
            <div className={styles.subscription__content}>
              <span>Free</span>
              <span>Interest on savings (7%-8%)</span>
              <span>Access to transition road map</span>
              <span>Business Startup Consulting</span>
            </div>
            <button onClick={handleClick}>Learn More</button>

            <div className={styles.plan__one}>
              <img src={cardPlanIcon} alt="card anchor icon" />
            </div>
            <div className={styles.plan__two}>
              <img src={cardPlanIcon} alt="card anchor icon" />
            </div>
          </div>
          <div className={styles.subscription__card_two}>
            <h3>PREMIUM PLAN</h3>
            <div className={styles.subscription__content}>
              <span>Subscription (N1,500)</span>
              <span>Interest on savings (9%-10%)</span>
              <span>Access to startup trainings</span>
              <span>Access to Business Mentors</span>
            </div>
            <button onClick={handleClick}>Learn More</button>

            <div className={styles.plan__one}>
              <img src={cardPlanIcon} alt="card anchor icon" />
            </div>
            <div className={styles.plan__two}>
              <img src={cardPlanIcon} alt="card anchor icon" />
            </div>
          </div>
        </div>
      </section>

      <section className={styles.testimonial__section}>
        <div className={styles.testimonial__paragraph}>
          <span></span>
          <h2>HEAR FROM OUR OWN</h2>
        </div>

        <div className={styles.testimonial_main}>
          <div className={styles.testimonial_image}>
            <div className={styles.testimonial_image_arrows}>
              <span>
                <img src={chevronArrow} alt="nav arrows" />
              </span>
              <span>
                <img src={chevronArrow} alt="nav arrows" />
              </span>
            </div>
            <img src={testimonialImg} alt="testimonial owner" />
          </div>

          <div className={styles.testimonial__content}>
            <h4>Random Name</h4>
            <blockquote>
              <span>
                <img src={leftQuote} alt="quote" />
              </span>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat.
              </p>
            </blockquote>
          </div>
        </div>
      </section>

      <section className={styles.nextStepSection}>
        <div className={styles.nextStepSection__left}>
          <span>
            <img src={purpleCircle} alt="" />
          </span>

          <span>
            <img src={pexelsImg1} alt="" />
          </span>

          <span>
            <img src={pexelsImg2} alt="" />
          </span>

          <span>
            <img src={greenCircle} alt="" />
          </span>
        </div>

        <div className={styles.nextStepSection__center}>
          <span></span>
          <h3>Ready to take the next step</h3>
          <p>Join Us now</p>
          <button onClick={handleClick}>Sign Up</button>
        </div>

        <div className={styles.nextStepSection__right}>
          <span>
            <img src={pexelsImg3} alt="" />
          </span>

          <span>
            <img src={greenCircle} alt="" />
          </span>
          <span>
            <img src={purpleCircle} alt="" />
          </span>

          <span>
            <img src={pexelsImg4} alt="" />
          </span>
        </div>
      </section>
      <Footer />
    </div>
  );
};

LandingPage.propTypes = {};

export default LandingPage;
